import './App.css';
import { FullPage, Slide } from 'react-full-page';

function App() {
  /* Header: nombre y descripción breve */
  /* Barra de enlaces */
  return (
    <FullPage>
      <Slide>
        <div className="App">
          <header className="App-header">
            <h1>Guillermo Villafuerte</h1>
            <p>Full Stack Developer</p> 
          </header>
        </div>
      </Slide>
    </FullPage>

  );
}

export default App;
